import { html, css } from 'Components';
import { Router } from 'Router';
import { Session, UrlParams } from 'Utils';

import utils from './utils.js';
import Step from './Step.js';

class Step5 extends Step {
  static get styles() {
    return [
      super.styles,
      css`
        .align {
          display: flex;
          justify-content: flex-start;
          margin-bottom:10px;
        }

        .align sl-input {
          width:250px;
        }

        @media (max-width: 670px) {
          .align sl-input {
            width:150px;
          }
        }

        .align > * {
          margin-right:10px;
        }

        label {
          font-size:1.1em;
          line-height:2em;
          min-width:120px;
          white-space:nowrap;
          display:inline-block;
        }

        .wish {
          margin:10px;
          font-size:1.1em;
          margin-top:20px;
        }

        m-icon {
          color:var(--sl-color-neutral-400);
          cursor:pointer;
          line-height:30px;
        }

        m-icon:hover {
          color:var(--sl-color-neutral-600);
        }

        .bt_icon::part(base)   {
          height: 30px;
          min-height:initial;
          line-height:initial ;
        }

        .bt_icon::part(label) {
          margin:0px;
          padding:0px;
        }

        #loader {
          text-align:center;
        }

        #loader img {
          animation: rotate 5s linear infinite;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .error_dialog m-icon {
          color:red;
          font-size:50px;
        }

        .modal_dialog::part(header-actions) {
          display:none;
        }

        modal-dialog::part(title) {
          padding:10px;
        }

        modal-dialog::part(body) {
          padding:10px;
          overflow:hidden;
          min-height:140px;
        }

        .center {
          text-align:center;
        }

        .permalink {
          word-break: break-all;
          display:block;
          font-size : 0.8em;
        }

        sl-skeleton {
          margin-bottom: 1rem;
        }

        sl-skeleton.large {
          height:100px;
        }
      `
    ];
  }

  static get properties() {
    return {
    };
  }

  constructor() {
    super();
  }

  async connectedCallback() {
    super.connectedCallback();
    this.who = UrlParams.get('who', 'sysdream');
    this.wish = UrlParams.get('wish', 'dida');

    if (!utils.isWhoAllowed(this.who)) { Router.go('/public'); return; }
    if (!utils.isWishAllowed(this.wish)) { Router.go('/public'); return; }
  }

  async firstUpdated() {
    super.firstUpdated();
    this.btNext = this.shadowRoot.querySelector('#bt_next');

    this.sessionData = await Session.reload();
    if (
      !this.sessionData.rt?.email || 
      !this.sessionData.rt?.emailCode || 
      !this.sessionData.rt?.emailValidated ||
      !this.sessionData.rt?.ticketNumberHash
    ) {
      Router.go('/public/mondossier/etape4'+window.location.search);
      return;
    }

    this.updateHash();
  }

  updateHash() {
    setTimeout(() => {
      let hash = this.sessionData.rt?.ticketNumberHash;
      if (hash) {
        hash = encodeURIComponent(hash);
        this.localUrl = `/public/mondossier/consulter/${hash}?who=`+this.who;
        this.fullUrl = `${HOME_URL}/public/mondossier/consulter/${hash}?who=`+this.who;
        Router.go(this.localUrl);
        return;
      }
      this.requestUpdate();
    }, 100);
  }

  goToDossier() {
    Router.go(this.localUrl);
  }

  render() {
    return html`
      <section-header img="assets/img/dossier.png">${document.title}</section-header>
      <div class="wish">
        ${!this.fullUrl ?
          html`` : 
          html`
            <sl-button @click="${this.goToDossier}" pill variant="primary">
              <m-icon slot="suffix" name="navigate_next" nogradient></m-icon>
              Acceder à mon dossier
            </sl-button>
            <sl-copy-button value="${this.fullUrl}"></sl-copy-button>
            <br/><br/>
          `
        }
      </div>
    `;
  }

  goPreviousStep() {
    Router.go('/public/mondossier/etape3'+window.location.search);
  }

  goNextStep() {
    Router.go('/public/mondossier/etape5');
  }

}

customElements.define('page-dossier-etape5', Step5);
