import { html, css } from 'Components';
import { Router } from 'Router';
import { Fetcher, Session, UrlParams, Sleep } from 'Utils';

import utils from './utils.js';
import Step from './Step.js';

class Step4 extends Step {
  static get styles() {
    return [
      super.styles,
      css`
        .align {
          display: flex;
          justify-content: flex-start;
          margin-bottom:10px;
        }

        .align sl-input {
          width:250px;
        }

        @media (max-width: 670px) {
          .align sl-input {
            width:150px;
          }
        }

        .align > * {
          margin-right:10px;
        }

        label {
          font-size:1.1em;
          line-height:2em;
          min-width:120px;
          white-space:nowrap;
          display:inline-block;
        }

        .wish {
          margin:10px;
          font-size:1.1em;
          margin-top:20px;
        }

        m-icon {
          color:var(--sl-color-neutral-400);
          cursor:pointer;
          line-height:30px;
        }

        m-icon:hover {
          color:var(--sl-color-neutral-600);
        }

        .bt_icon::part(base)   {
          height: 30px;
          min-height:initial;
          line-height:initial ;
        }

        .bt_icon::part(label) {
          margin:0px;
          padding:0px;
        }

        #loader {
          text-align:center;
        }

        #loader img {
          animation: rotate 5s linear infinite;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .modal_dialog::part(header-actions) {
          display:none;
        }

        modal-dialog::part(title) {
          padding:10px;
        }

        modal-dialog::part(body) {
          padding:10px;
          overflow:hidden;
          min-height:140px;
        }

        .center {
          text-align:center;
        }
      `
    ];
  }

  static get properties() {
    return {
      showSubmitDialog: { type: Boolean },
      showSubmitDialogError: { type: Boolean },
      showEmailCheckFailed: { type: Boolean }
    };
  }

  constructor() {
    super();
    this.showSubmitDialog = false;
    this.showSubmitDialogError = false;
    this.showEmailCheckFailed = false;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.who = UrlParams.get('who', 'sysdream');
    this.wish = UrlParams.get('wish', 'dida');

    if (!utils.isWhoAllowed(this.who)) {
      Router.go('/public');
      return;
    }

    if (!utils.isWishAllowed(this.wish)) {
      Router.go('/public');
      return;
    }

    const sessionData = await Session.reload();
    if (!sessionData.rt?.email || !sessionData.rt?.emailCode) {
      Router.go('/public/mondossier/etape3'+window.location.search);
      return;
    }
  }

  async firstUpdated() {
    super.firstUpdated();
    this.inputCodeField = this.shadowRoot.querySelector('input-code');
    this.btNext = this.shadowRoot.querySelector('#bt_next');
    this.loader = this.shadowRoot.querySelector('#loader');

    await Sleep(100);
    this.inputCodeField && this.inputCodeField.setFocus();
  }

  async handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.showSubmitDialog = true;
    let response;


    try {
      response = await Fetcher.post('public/rt/email/check', { code: this.inputCodeField.getValue() })
    } catch(e) {
      console.log(e);
      this.closeDialog();
      this.showSubmitDialogError = true;
      return;
    }

    if (!response) {
      this.closeDialog();
      this.showSubmitDialogError = true;
      return;
    }

    this.closeDialog();

    if (response.ok === false) {
      if (response.error === 'EEMAIL_CHECK_FAILED') {
        this.showEmailCheckFailed = true;
      } else {
        this.showSubmitDialogError = true;
      }
      return;
    }

    Router.go('/public/mondossier/etape5'+window.location.search);
  }

  closeDialog() {
    this.showSubmitDialog = false;
    this.showSubmitDialogError = false;
    this.showForbidden = false;
  }

  async handleInputCodeChange(e) {
    if (e.detail.value) {
      this.btNext.disabled = false;
      await Sleep(1);
      this.btNext.focus();
    } else {
      this.btNext.disabled = true;
    }
  }

  render() {
    return html`
      <section-header img="assets/img/dossier.png">${document.title}</section-header>
      <!--<div class="wish">${utils.getWishTitle(this.wish)}.</div>-->
      <form @submit="${this.handleSubmit}">
        Un email vient de vous être envoyé, il contient un code a 4 chiffres que vous devez resaisir ou copier coller ci-dessous.<br/><br/>
        <div class="center"><input-code @change="${this.handleInputCodeChange}"></input-code></div>
      </form>
      
      <hr-custom></hr-custom><br/>
      <div class="buttons">
        <sl-button @click="${this.goPreviousStep}" variant="text">
          <m-icon slot="prefix" name="navigate_before" nogradient></m-icon>
            Retour
        </sl-button>
        <sl-button @click="${this.handleSubmit}" variant="primary" disabled id="bt_next">
          <m-icon slot="suffix" name="navigate_next" nogradient></m-icon>
            Suivant
        </sl-button>
      </div>

      <modal-dialog label="Vérification de votre email en cours .." ?open="${this.showSubmitDialog}" class="modal_dialog">
        <br/><br/>
        <sl-progress-bar indeterminate></sl-progress-bar>
        <br/><br/>
      </modal-dialog>

      <modal-dialog label="Erreur" ?open="${this.showSubmitDialogError}" @sl-after-hide="${this.closeDialog}" class="error_dialog">
        <p class="center">
          <m-icon name="sync_problem" nogradient></m-icon><br/><br>
          Une erreur est survenue pendant la vérification de votre dossier.<br/><br/>
          Merci de réessayer ultérieurement.
        </p>
      </modal-dialog>

      <modal-dialog label="Echec" ?open="${this.showEmailCheckFailed}" @sl-after-hide="${this.closeDialog}" class="error_dialog">
        <p class="align">
          <m-icon name="do_not_disturb_on" nogradient></m-icon>
          Le code que vous avez saisi est incorrect.
        </p>
      </modal-dialog>
    `;
  }

  goPreviousStep() {
    Router.go('/public/mondossier/etape3'+window.location.search);
  }

}

customElements.define('page-dossier-etape4', Step4);
