import { html, css } from 'Components';
import { Router } from 'Router';
import { UrlParams } from 'Utils';

import utils from './utils.js';
import Step from './Step.js';

class Step2 extends Step {
  static get styles() {
    return [
      super.styles,
      css``
    ];
  }

  static get properties() {
    return {
      selectedRadio: { type: String },
    };
  }

  constructor() {
    super();
    this.defaultRadioValue = 'dida';
    this.selectedWish = this.defaultRadioValue;
    this.selectedWishUrlParam = 'wish';
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('popstate', this.handlePopState.bind(this));

    this.selectedWish = UrlParams.get(this.selectedWishUrlParam, this.defaultRadioValue);
    this.selectedWho = UrlParams.get('who', 'sysdream');

    if (!utils.isWhoAllowed(this.selectedWho)) {
      Router.go('/public');
      return;
    }

    if (!utils.isWishAllowed(this.selectedWish)) {
      Router.go('/public');
      return;
    }

    UrlParams.set(this.selectedWishUrlParam, this.selectedWish);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('popstate', this.handlePopState.bind(this));
  }

  handlePopState() {
    this.selectedWish = UrlParams.get(this.selectedWishUrlParam, this.defaultRadioValue);
  }

  handleRadioChange(e) {
    this.selectedWish = e.target.value;
    UrlParams.set(this.selectedWishUrlParam, this.selectedWish);
  }

  render() {
    const wishDisabled = ['incass', 'exo'];
    return html`
      <div radio="${this.selectedWish}">
        <section-header img="assets/img/dossier.png">${document.title}</section-header>
        <form>
          <sl-radio-group size="small" @sl-change="${this.handleRadioChange}" size="small" value="${this.selectedWish}">
            ${utils.wishes.map(wish => html`<sl-radio .disabled=${wishDisabled.includes(wish.value)} value="${wish.value}">${wish.radioLabel}</sl-radio>`)}
          </sl-radio-group>
        </form>
        
        <hr-custom></hr-custom><br/>
        <div class="buttons">
          <sl-button @click="${this.goPreviousStep}" variant="text">Précédent</sl-button>
          <sl-button @click="${this.goNextStep}" variant="primary" id="bt_next">
            <m-icon slot="suffix" name="navigate_next" nogradient></m-icon>
            Suivant
          </sl-button>
        </div>
      </div>
    `;
  }

  goPreviousStep() {
    Router.go('/public/mondossier/etape1'+window.location.search);
  }

  goNextStep() {
    Router.go('/public/mondossier/etape3'+window.location.search);
  }

}

customElements.define('page-dossier-etape2', Step2);