import { PageElement, html, css } from 'Components';

class Error503 extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .error_container {
          padding:20px;
          text-align:center;
          font-size:1em;
        }

        .error_container m-icon {
          display:block;
          text-align:center;
          font-size:60px;
          color:var(--sl-color-danger-500);
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'503 - Service unavailable',
            text:'The application is currently under maintenance. Please try again later.',
            back:'Back',
          }
        },
        french:{
          translation: {
            title:'503 - Service indisponible',
            text:'L\'application est actuellement en cours de maintenance. Veuillez réessayer ultérieurement.',
            back:'Retour',
          }
        }
      }
    ]
  }

  render() {
    return html`
      <br/>
      <div class="error_container">
        <m-icon name="pending"></m-icon>
        <p>${this._tl('text')}</p>
        <br/>
      </div>
    `;
  }

  goHome() {
    window.history.back();
  }

}

customElements.define('error-maintenance', Error503);