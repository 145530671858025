import { PageElement, html, css } from 'Components';
import { Notify } from 'Utils';
import App from 'App';

import './404.js';
import './403.js';
import './503.js';

class ErrorList extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .container {
          padding:20px;
        }

        sl-button {
          width:300px;
          margin-right:10px;
        }

        sl-button::part(label) {
          font-weight:bold;
        }

        .flex {
          display:flex;
          justify-content:flex-start;
          align-items:center;
          margin-bottom:10px;
        }

        .flex div {
          width:500px;
          font-size:14px;
        }

        h2 {
          margin-top:5px;
          color: var(--nsys-blue3);
          text-transform: uppercase;
          font-family: Calibri;
          border-bottom:1px solid var(--sl-color-gray-200);
          font-size:1em;
          line-height:2.2em;
        }

        @media (max-width: 670px) {
          sl-button {
            width:100%;
            margin-bottom:10px;
          }

          .flex {
            flex-direction:column;
          }

          .flex div {
            width:100%;
          }
        }

      `
    ];
  }

  static get translations() {
    return [
      super.translations,
        {
        english:{
          translation: {
            title:'Error Messages List',
            sub_title:'Errors related to server exchanges (API).',
            text:`These popups appear when the application encounters a problem with the server.
                  Click the buttons to display the associated messages.`,
            back:'Back to home',
          }
        },
        french:{
          translation: {
            title:'Liste des messages d\'erreur',
            sub_title:'Erreurs relatives aux échanges avec le serveur (API).',
            text:`Ces popups apparaîssent lorsque l'application rencontre un problème avec le serveur.
                  Cliquez les boutons pour faire apparaître les messages associés.`,
            back:'Retourner à l\'accueil',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.codes = {};
    
    // sort by alphabetical order
    Object.keys(Notify.codes).sort().forEach(key => {
      this.codes[key] = Notify.codes[key];
    });
  }

  handleClick(ev) {
    Notify.errorCode(ev.target.innerText);
  }

  render() {
    return html`
      <section-header micon="error" backroute="/private/doc">${this._tl('title')}</section-header>
      <br/>
      <div class="container">
          <h2>${this._tl('sub_title')}</h2>
          <br/>
          ${this._tl('text')}<br/><br/>
          <br/><br/>
        ${Object.keys(this.codes).map((key) => {
          return html`
            <div class="flex">
              <sl-button variant="danger" size="small" pill @click="${this.handleClick}">${key}</sl-button>
              <div>${this.codes[key][App.config.language].help}</div>
            </div>
          `})}
          <br/><br/>
          <a-route href="/">${this._tl('back')}</a-route>
      </div>
    `;
  }

  goBack() {
    window.history.back();
  }

}

customElements.define('error-list', ErrorList);